@import 'variables';

html {
  font-size: 62.5%;
}

body {
  position: relative;
  margin: 0;
  background: #36587a;
  color: #fff;
  font: 1.4rem $main-font;
}

body,
html {
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

[dir='rtl'] {
  .MuiInputAdornment-positionStart {
    margin-left: 0;
  }
}
.date-multiple_tooltip {
  z-index: 11111;
}

.container {
  max-width: 160rem;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
