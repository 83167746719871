$white: #ffffff;
$alto: #dadada;
$java: #14b1d6;
$jaffa: #f2994a;
$cream-can: #f2c94c;

$teal-blue: #06466a;

$color-primary-dark: #264462;
$color-primary: #36587a;
$color-primary-light: #2d4f71;
$color-secondary: #5fb800;

$color-default: rgba(#000, 0.87);
$color-border: #eeeeee;
$color-table: #888d8f;
$color-grey-dark: #546e7b;
$color-grey-light: #f6f8f8;
$color-gray: #cfdce7;

$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

$main-font: 'Arial', 'Helvetica', sans-serif;

// New Variables color
$color-white: #fff;
$color-blue1: #cfdce7;

$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1025,
  xl: 1400,
  xxl: 1580,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorPrimaryDark: $color-primary-dark;
  colorSecondary: $color-secondary;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorTable: $color-table;
  colorGreyDark: $color-grey-dark;
  colorGreyLight: $color-grey-light;
  colorGray: $color-gray;

  // New Variables color
  colorBlue1: $color-blue1;
  colorBlueSecondary: $teal-blue;
  colorWhite: $color-white;
}
