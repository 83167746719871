@import 'variables';

.multiple-select {
  .MuiAutocomplete-tag {
    max-width: 100px;
  }
}

.jodit-react-container {
  .jodit-container:not(.jodit_inline) {
    border: none;

    &.jodit_disabled {
      background: inherit;
    }
  }

  .jodit-toolbar__box {
    margin-bottom: 10px;
    padding: 5px;
    background: $color-primary-light;
    border: none;
    border-radius: 8px;
  }

  .jodit-toolbar-editor-collection_mode_horizontal:after {
    display: none;
  }

  .jodit-toolbar-button__button:hover:not([disabled]) {
    background-color: $color-primary-light;
  }

  .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
    border-color: transparent;
  }

  .jodit-toolbar-button__trigger {
    display: none;
  }

  .jodit-toolbar-button {
    margin: 0 5px !important;
    border-radius: 5px;

    & > button {
      color: $color-gray;
    }
  }

  .jodit_container:not(.jodit_inline) {
    background: $color-primary-light;

    .jodit_workplace {
      border: none;
    }
  }

  .jodit-status-bar {
    display: none;
  }

  .jodit-icon {
    fill: rgba($color-gray, 0.5);
  }

  .jodit-wysiwyg {
    text-align: left;
    font: 14px/16px $main-font;
    color: $color-white;
  }

  .jodit-placeholder {
    font: 14px/16px $main-font;
    color: $color-gray;
  }
}
