@import 'src/styles/variables';

.root {
  display: grid;
  height: 100%;
  box-sizing: border-box;
  background: #17508a url('../../images/login-bg.jpg') no-repeat top center;
  background-size: cover;
  color: white;
  grid-template-rows: auto 1fr auto;
}

.header {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo {
  width: 27rem;
  height: 10rem;
}

.title {
  font-size: 4rem;
  margin-top: 3.5rem;
}

.subTitle {
  margin-top: 1.2rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  max-width: 67rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .title {
    font-size: 3rem;
  }

  .subTitle {
    font-size: 2.4rem;
  }
}
